import classNames from 'classnames';
import { ReactNode, FocusEvent, ChangeEvent } from 'react';
import { Label } from 'shared/components/elements/elements.components';
import { Translation } from 'shared/components/translation/translation.component';
import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { useFormControlAsState } from 'app/hooks/forms/use-form-control-as-state.hook';
import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';

interface OwnProps {
  id: string;
  className?: string;
  required?: boolean;
  suppressLabel?: boolean;
  maxLength?: number;
  label?: ResourceKey | (() => ReactNode);
  field: NavCarrierFormControl;
  renderErrors?: (field: NavCarrierFormControl) => ReactNode;
  onChange?: (e: ChangeEvent) => any;
  onBlur?: (e: FocusEvent) => any;
  onFocus?: (e: FocusEvent) => any;
  type?: string;
  name?: string;
  title?: string;
  placeholder?: string;
  step?: string;
  readOnly?: boolean;
  hasError?: boolean;
  fsMask?: boolean;
}

export const Input = ({ field, label, renderErrors, className, onChange, onBlur, suppressLabel, required, hasError, fsMask, ...props }: OwnProps) => {
  const [value, innerOnChange, innerOnBlur] = useFormControlAsState<string>(field, onChange, onBlur);
  return (
    <div className={classNames('form-group', { 'has-error': field.hasErrors() || hasError }, className)}>
      <ErrorBoundary>
        {Boolean(label) && !suppressLabel &&
          <Label htmlFor={props.id} required={required}>
            {typeof label === 'function' ? label() : <Translation resource={label} />}
          </Label>
        }
        <input
          {...props}
          className={"form-control" + (fsMask ? " fs-mask" : "")} disabled={field.disabled}
          value={value} onChange={innerOnChange} onBlur={innerOnBlur}
        />
        {typeof renderErrors === 'function' && renderErrors(field)}
      </ErrorBoundary>
    </div>
  );
};
